import React, { useState } from "react";
import { MdDeleteForever } from "react-icons/md";
import "./Task.css";

function Task({ task, index, deleteTask }) {
  const [checked, setChecked] = useState(false);
  return (
    <div className={(checked?'checked ': '')+'task-card'}>
      <input
        type="checkbox"
        checked={checked}
        onChange={(event) => {
          setChecked(event.target.checked)
        }}
      />
      <div
        className="task-title"
        style={{
          textDecoration: checked ? "line-through" : "none",
        }}
      >
        {task.title}
      </div>
      <div className="task-time">
        <input type="time" value={task.time} disabled />
      </div>
      <MdDeleteForever
        onClick={(event) => {
          event.preventDefault();
          deleteTask(index);
        }}
        className="delete-note"
      />
    </div>
  );
}

export default Task;
