import React, { useState } from "react";
import { MdOutlinePostAdd } from "react-icons/md";
import "./Addtask.css";

function Addtask(props) {
  const [task, setTask] = useState({});
  function handleChange(event) {
    const { name, value } = event.target;
    setTask((pre) => {
      return { ...pre, [name]: value };
    });
    // console.log(task);
  }

  return (
    <div className="add-form">
      <form>
        <input
          type="text"
          onChange={handleChange}
          className="input-group input-task"
          placeholder="Task"
          name="title"
        />
        <input
          type="time"
          onChange={handleChange}
          className="input-group input-time"
          name="time"
        />
        <button>
          <MdOutlinePostAdd
            onClick={(event) => {
              event.preventDefault();
              if (task.title) {
                props.addTask(task);
              }
            }}
            className="add-btn"
          />
        </button>
      </form>
    </div>
  );
}

export default Addtask;
