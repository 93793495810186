import { useEffect, useRef, useState } from "react";
import "./App.css";
import Addtask from "./components/Addtask/Addtask";
import Navbar from "./components/NavBar/Navbar";
import Task from "./components/Task/Task";
function App() {
  const [tasks, setTasks] = useState([]);
  function addTask(task) {
    console.log(task);
    setTasks([...tasks, task]);
  }

  function deleteTask(id) {
    setTasks(tasks.filter((task, index) => index !== id));
  }

  const ref = useRef(null);
  useEffect(() => {
    if (tasks.length) {
      ref.current?.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }
  }, [tasks.length, ref]);

  return (
    <div className="App">
      <Navbar />
      <div className="tasks-container">
        {tasks.length === 0 ? "No Tasks" : ""}
        {tasks.map((task, index) => {
          const key = index + 1;
          return (
            <Task task={task} key={key} index={index} deleteTask={deleteTask} />
          );
        })}
        <div ref={ref}></div>
      </div>

      <Addtask addTask={addTask} />
    </div>
  );
}

export default App;
